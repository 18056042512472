import WideskNavigation        from '@widesk-ui/components/Navigation';
import navigation              from '@/navigation';
import { navigationSettings }  from '@/navigation';
import useTheme                from '@widesk-ui/hooks/useTheme';
import View                    from '@widesk-ui/components/View';
import Link                    from '@widesk-ui/components/Link';
import ProjectSelector         from './ProjectSelector';
import { BuildOutlined }       from '@ant-design/icons';
import { EnvironmentOutlined } from '@ant-design/icons';
import { LayoutOutlined }      from '@ant-design/icons';
import { PushpinOutlined }     from '@ant-design/icons';
import { CaretDownOutlined }   from '@ant-design/icons';
import { useLocation }         from 'react-router-dom';
import { useParams }           from 'react-router-dom';
import useAccount              from '@/hooks/useAccount';
import { PROJECT_PAGE_PREFIX } from '@/appConfig';
import ProjectModel            from '@models/map/ProjectModel';
import MapModel                from '@models/map/MapModel';
import PlaceModel              from '@models/map/PlaceModel';
import PlaceTypeModel          from '@models/map/PlaceTypeModel';

export default function LayoutSider() {
	const theme = useTheme();
	const { account } = useAccount();

	const { id, projectId: pid } = useParams();

	const location = useLocation();

	let projectId = pid;

	if (id && !pid && location.pathname.startsWith(`/${PROJECT_PAGE_PREFIX}/`)) {
		projectId = id;
	}

	return (
		<>
			<View className="sider-top" flex style={{ overflow: 'auto' }}>
				<View center paddingT={theme.marginMD} paddingB={theme.marginXS * 1.8}>
					<Link to="/">
						<img
							className="app-logo"
							style={{ width: '195px', height: '30px', maxWidth: '80%', display: 'block', margin: 'auto' }}
							src="/logo-white.svg"
							alt=""
						/>
					</Link>
				</View>
				{account && (
					<View className="sider-anim" flex style={{ overflow: 'auto' }}>
						<ProjectSelector placement="bottomLeft" projectId={projectId} icon={<CaretDownOutlined />} type="primary">
							Choisir un projet
						</ProjectSelector>
						<WideskNavigation items={navigation} />
						{projectId && (
							<WideskNavigation items={[
								{ label: 'Aperçu général', icon: <LayoutOutlined />, link: (new ProjectModel({ id: projectId })).selectedPagePath },
								{ label: 'Cartes', icon: <EnvironmentOutlined />, link: MapModel.pagePath('splitView') },
								{ label: 'Places', icon: <PushpinOutlined />, link: PlaceModel.pagePath('splitView') },
								{ label: 'Types de places', icon: <BuildOutlined />, link: PlaceTypeModel.pagePath('splitView') },
							]} />
						)}
					</View>
				)}
			</View>

			{account && (
				<div className="sider-bottom sider-anim">
					{/* Paramètres */}
					<WideskNavigation
						items={navigationSettings}
					/>
				</div>
			)}
		</>
	);
}
