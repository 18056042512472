import './projectSelector.scss';
import ProjectModel            from '@/models/services/map/ProjectModel';
import { CaretDownOutlined }   from '@ant-design/icons';
import { RightOutlined }       from '@ant-design/icons';
import Button                  from '@widesk-ui/components/Button';
import { ButtonProps }         from '@widesk-ui/components/Button';
import Link                    from '@widesk-ui/components/Link';
import Popover                 from '@widesk-ui/components/Popover';
import Title                   from '@widesk-ui/components/Title';
import View                    from '@widesk-ui/components/View';
import { observer }            from 'mobx-react';
import appStore                from '@/stores/appStore';
import SelectProject           from '@/selects/services/map/SelectProject';
import { navigate }            from '@widesk-ui/hooks/useNavigate';
import { useSetActiveProject } from '@/hooks/useActiveProject';
import { useEffect }           from 'react';
import { useState }            from 'react';
import useAccount              from '@/hooks/useAccount';
import { PROJECT_PAGE_PREFIX } from '@/appConfig';

interface IProjectSelectorProps {
	projectId?: id;
	placement?: 'top' | 'bottom' | 'bottomLeft' | 'topLeft';
}

const ProjectSelector = observer((props: ButtonProps & IProjectSelectorProps) => {
	const id = props.projectId;
	const projectCollection = appStore.projectCollection;
	const project = useSetActiveProject(id);
	const { accountUrn } = useAccount();

	const [open, setOpen] = useState(false);
	useEffect(() => {
		setOpen(false);
	}, [project?.id]);

	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen);
	};

	return (
		<Popover
			trigger="click"
			open={open}
			onOpenChange={handleOpenChange}
			placement={props.placement}
			content={
				<div className="project-selector">
					<div className="project-selector__subheading">
						<SelectProject
							placeholder="Choisir un projet"
							identifier="id"
							onChange={id => navigate(`/${PROJECT_PAGE_PREFIX}/${id}`)}
							filters={{
								'owner.accountUrn': accountUrn,
							}}
						/>
					</div>
					{projectCollection.isLoaded && projectCollection.models.length === 0 ? (
						<em>Vous n'avez pas encore ajouté de projet.</em>
					) : (
						<div className="project-selector__list">
							{projectCollection.models.map(project => (
								<Link key={project.id} to={`/${PROJECT_PAGE_PREFIX}/${project.id}`} >
									<View row gap="sm">
										<Title level={5}>{project.renderName({ imageSize: 'small' })}</Title>
									</View>
								</Link>
							))}
						</div>
					)}
					<div className="project-selector__actions">
						<Link to={ProjectModel.pagePath('splitView')}>Voir tous les projets <RightOutlined /></Link>
					</div>
				</div>
			}
		>
			{project ? (
				<Button {...props} icon={(
					<View row gap="xs" center>
						<CaretDownOutlined />
						<Title bold level={5}>{project.renderName({ imageSize: 'small' })}</Title>
					</View>
				)} children={undefined} />
			) : (
				<Button {...props} />
			)}
		</Popover>
	);
});

export default ProjectSelector;
