type ENV = 'local' | 'dev' | 'demo' | 'preprod' | 'prod';

export const API_ENV = import.meta.env.VITE_API_ENV as ENV || ((): ENV => {
	if (location.hostname.startsWith('dev-')) return 'dev';
	else if (location.hostname.startsWith('demo-')) return 'demo';
	else if (location.hostname.startsWith('preproduction-')) return 'preprod';

	return 'prod';
})();

export const PROJECT_PAGE_PREFIX = 'current-project';

const apiEndpoints: Record<ENV, string> = {
	local: 'https://tokamap-api.docker.localhost',
	dev: 'https://dev-tokamap-api.staging.stonecode.io',
	demo: 'https://demo-tokamap-api.staging.stonecode.io',
	preprod: 'https://preproduction-tokamap-api.staging.stonecode.io',
	prod: 'https://api.tokamap.com',
};

const baseApiEndpoint = apiEndpoints[API_ENV];

window.FRONT_CONFIG = {
	devToolbarEnabled: API_ENV === 'dev' || API_ENV === 'local',
	env: API_ENV,
	displayEnvApi: API_ENV !== 'prod',
};

window.APP_CONFIG = {
	services: [
		{
			id: 'account',
			configurations: {
				api_endpoint: `${baseApiEndpoint}/account`,
			},
		},
		{
			id: 'map',
			configurations: {
				api_endpoint: `${baseApiEndpoint}/map`,
			},
		},
		{
			id: 'file',
			configurations: {
				api_endpoint: `${baseApiEndpoint}/file`,
			},
		},
		{
			id: 'admin',
			configurations: {
				api_endpoint: `${baseApiEndpoint}/admin`,
			},
		},
	],
};