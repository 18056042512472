import { PROJECT_PAGE_PREFIX } from '@/appConfig';
import React                   from 'react';
import { useParams }           from 'react-router-dom';

export default [
	{
		path: `/${PROJECT_PAGE_PREFIX}/:projectId`,
		lazy: async () => {
			const Module = await import('../../pages/services/map/ProjectDashboard/ProjectDashboardPage');
			return {
				Component: () => {
					const { projectId: id } = useParams();
					return React.createElement(Module.default, { id });
				},
			};
		},
	},
];