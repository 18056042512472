import { ModelClass }          from '@mathquis/modelx/lib/types/collection';
import ApiModel                from '@widesk-core/models/ApiModel';
import appStore                from '@/stores/appStore';
import { PROJECT_PAGE_PREFIX } from '@/appConfig';

type ApiModelWithProject = ApiModel & { project: { id: string } };

export default function WithProjectPagePath<T extends ModelClass<ApiModel>>(Base: T) {
	return class WithProjectPagePath extends Base {

		public static pagePath(...args: Parameters<typeof ApiModel.pagePath>) {
			const path = ApiModel.pagePath.call(this, ...args);
			return `/${PROJECT_PAGE_PREFIX}/${appStore.activeProject?.id}${path}`;
		}

		public get pagePath(): string {
			return `/${PROJECT_PAGE_PREFIX}/${(this as unknown as ApiModelWithProject).project.id}${this.path}`;
		}
	};
}
