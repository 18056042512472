import { useLocation }              from 'react-router-dom';
import { useParams }                from 'react-router-dom';
import AntdBreadcrumb               from 'antd/lib/breadcrumb';
import theme                        from 'antd/lib/theme';
import React                        from 'react';
import breadcrumbBarStore           from '@widesk-ui/stores/breadcrumbBarStore';
import View                         from '@widesk-ui/components/View';
import { getGlobalBreadcrumbRules } from '@widesk-ui/components/BreadcrumbV2/globalRules';
import { observer }                 from 'mobx-react-lite';

export type RuleExecReturn = {
	title: React.ReactNode;
	href?: string;
	pageTitle?: string;
}

export type BreadcrumbRule = {
	regex?: RegExp;
	exec: (params: any) => RuleExecReturn;
}

function isPrefixRegex(specific: RegExp, general: RegExp): boolean {
	return general.source.startsWith(specific.source);
}

function sortRulesBySpecificity(rules: BreadcrumbRule[]): BreadcrumbRule[] {
	return [...rules].sort((a, b) => {
		// Si a.regex ou b.regex est undefined, les mettre en premier
		if (!a.regex && !b.regex) return 0;
		if (!a.regex) return -1;
		if (!b.regex) return 1;

		// Comparaison des regex
		if (isPrefixRegex(a.regex, b.regex)) return -1; // a est plus général
		if (isPrefixRegex(b.regex, a.regex)) return 1;  // b est plus général
		return 0; // Sinon, garder l'ordre initial
	});
}

const BreadcrumbV2 = observer((props: { rules: BreadcrumbRule[] }) => {
	if (!breadcrumbBarStore.enabled) return null;

	return <BreadcrumbBar {...props} />;
});

const BreadcrumbBar = React.memo((props: { rules: BreadcrumbRule[] }) => {
	const location = useLocation();
	const params = useParams();
	const { token } = theme.useToken();

	const rules = sortRulesBySpecificity([...props.rules, ...getGlobalBreadcrumbRules()]);

	const items = rules
		.filter(rule => rule.regex ? rule.regex.test(location.pathname) : true)
		.map(rule => rule.exec(params));

	if (items.at(-1)?.pageTitle) {
		document.title = items.at(-1)?.pageTitle || '';
	}

	return (
		<View
			bg={token.colorBgContainer}
			minHeight={50}
			centerV
			paddingH="lg"
			separatorB
			row
			spread
		>
			<AntdBreadcrumb items={items} />

			{!!breadcrumbBarStore.rightComponent && breadcrumbBarStore.rightComponent()}
		</View>
	);
});

export default BreadcrumbV2;